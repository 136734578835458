body { color: $COL14; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL25;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h2 {
color: $COL17;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 26px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 35px;

}
}
h3 {
color: $COL18;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 28px;

}
@media #{$large-up} {
font-size: 30px;

}
}
h4 {
color: $COL19;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL20;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: $COL21;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: $COL22;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: $COL23}
a:hover {color: $COL24}
/* shade 1:2 */
.MES2 {
background-color: $COL9;
color: $COL4;
border-radius: 2px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

@media #{$medium-up} {
padding: 30px;

}
 }
h1.MEC2, h2.MEC2, h3.MEC2, h4.MEC2, h5.MEC2, h6.MEC2 { color: $COL4;
 }
h1.MEC2 { font-size: 22.5px; }
h1.MEC2 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC2 { font-size: 23.4px; }
h2.MEC2 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC2 { font-size: 18px; }
h3.MEC2 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC2 { font-size: 16.2px; }
h4.MEC2 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC2 { font-size: 16.2px; }
h5.MEC2 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC2 { font-size: 14.4px; }
h6.MEC2 { @media #{$large-up} { font-size: 18px; }; }
cite.MEC2{
color: $COL4;
}
/* News:17 */
.MES17 {
color: $COL4;
padding: 20px;

@media #{$medium-up} {
padding: 30px;

}
 }
h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: $COL15;
 }
h1.MEC17 { font-size: 22.5px; }
h1.MEC17 { @media #{$medium-up} { font-size: 28px; }; }
h1.MEC17 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC17 { font-size: 23.4px; }
h2.MEC17 { @media #{$medium-up} { font-size: 24px; }; }
h2.MEC17 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC17 { font-size: 18px; }
h3.MEC17 { @media #{$medium-up} { font-size: 22.4px; }; }
h3.MEC17 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC17 { font-size: 16.2px; }
h4.MEC17 { @media #{$medium-up} { font-size: 20px; }; }
h4.MEC17 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC17 { font-size: 16.2px; }
h5.MEC17 { @media #{$medium-up} { font-size: 16px; }; }
h5.MEC17 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC17 { font-size: 14.4px; }
h6.MEC17 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC17 { @media #{$large-up} { font-size: 18px; }; }
cite.MEC17{
color: $COL4;
}
/* Logout Button:29 */
.MES29 {
background-color: $COL12;
&:hover { background-color: $COL12;}
color: $COL2;
&:hover { color: $COL2;}
padding: 0;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL2 transparent;
 }
/* Page separator:28 */
.MES28 {
& > hr {border-width: 0 0 1px 0;}
& > hr {border-color: transparent transparent $COL27 transparent;}
& > hr {border-bottom-style: solid;}
 }
/* Responsive Menu:27 */
nav.responsive-menu {
.menu-item.MEC27{background-color: $COL4;
}
& .menu-item.MEC27, & .menu-item.MEC27 > div.menu-item-wrap{ & > a.MEC27, & > i{color: $COL2;
text-transform: capitalize;
text-align: center;
}
  }
& .menu-item.MEC27 { border:0;
border-color: $COL2;
border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px;}

& .sub-menu{ .sub-menu {}}

 }
/* Contact Form:26 */
.me-block.me-Form.MES26,  body.MES26 {background-color:transparent;
  
 input[type='submit']{border-style: solid;background-color: $COL15;
border-width: 0;
color: $COL2;
}
@include placeholder($COL4);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
border-color: $COL15;
background-color: $COL26;
color: $COL4;
}
::-webkit-input-placeholder { color: $COL4; opacity: 0.5;}
:-moz-placeholder {color: $COL4; opacity: 0.5;} 
::-moz-placeholder {color: $COL4; opacity: 0.5;}
:-ms-input-placeholder {color: $COL4; opacity: 0.5;}

  }
/* Primary:25 */
.MES25 {
background-color: $COL15;
color: $COL16;
 }
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: $COL16;
 }
cite.MEC25{
color: $COL16;
}
/* Primary:24 */
.MES24 {
background-color: $COL15;
color: $COL16;
 }
/* Banner Slider:23 */
.MES23 {
& .slick-dots {text-align: center;
 button {margin: 0 10px;
;border-color: $COL13;
border-radius: 50%;
border-style:solid;
border-width:5px;
@media #{$medium-up} {
border-width: 5px;
};
@media #{$large-up} {
border-width: 5px;};
&:hover{border-color: $COL5;
;}
background-color: $COL9;
background-clip: padding-box;
&:hover {background-color: $COL6;}
width:20px;
height:20px;
@media #{$medium-up} {
width:20px;
height:20px;
};
@media #{$large-up} {
width:20px;
height:20px;
};
&:hover{background-color: $COL6;
;}}}
& .slick-dots .slick-active {
  button{
border-color: $COL5;
background-color: $COL6;
  }
}
 }
/* Main Menu:22 */
nav.me-Menu.MES22 {
& .menu-item.MEC22, & .menu-item.MEC22 > div.MEC22{ & > a.MEC22{color: $COL4;
}
 &:hover > a.MEC22{color: $COL4;
}
 }
&.horizontal > .menu-item.MEC22 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC22 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC22 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC22 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* FAQ:21 */
details.MES21 {
& > summary{background-color: $COL2;
}
& > summary{padding: 20px 10px;}

& > article{padding: 15px;}

& > summary{border-width: 0 0 1px 0;
border-style:dotted;
border-color: transparent transparent $COL6 transparent;
} }
/* Orange button:19 */
.MES19 {
background-color: $COL11;
color: $COL2;
&:hover { color: $COL2;}
border-radius: 5px;
padding: 10px;

@media #{$medium-up} {
padding: 10px 30px;

}
border-width: 1px;
border-style: solid;
border-color: $COL2;
 }
/* Case Study:18 */
.MES18 {
background-color: $COL6;
color: $COL2;
padding: 20px;

@media #{$medium-up} {
padding: 30px;

}
 }
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: $COL2;
 }
h1.MEC18 { font-size: 22.5px; }
h1.MEC18 { @media #{$medium-up} { font-size: 28px; }; }
h1.MEC18 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC18 { font-size: 23.4px; }
h2.MEC18 { @media #{$medium-up} { font-size: 24px; }; }
h2.MEC18 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC18 { font-size: 18px; }
h3.MEC18 { @media #{$medium-up} { font-size: 22.4px; }; }
h3.MEC18 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC18 { font-size: 16.2px; }
h4.MEC18 { @media #{$medium-up} { font-size: 20px; }; }
h4.MEC18 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC18 { font-size: 16.2px; }
h5.MEC18 { @media #{$medium-up} { font-size: 16px; }; }
h5.MEC18 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC18 { font-size: 14.4px; }
h6.MEC18 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC18 { @media #{$large-up} { font-size: 18px; }; }
cite.MEC18{
color: $COL2;
}
/* table header:16 */
.MES16 {
background-color: $COL4;
color: $COL2;
 }
h1.MEC16, h2.MEC16, h3.MEC16, h4.MEC16, h5.MEC16, h6.MEC16 { color: $COL2;
 }
h1.MEC16 { font-size: 20px; }
h1.MEC16 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC16 { font-size: 20.8px; }
h2.MEC16 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC16 { font-size: 16px; }
h3.MEC16 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC16 { font-size: 14.4px; }
h4.MEC16 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC16 { font-size: 14.4px; }
h5.MEC16 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC16 { font-size: 12.8px; }
h6.MEC16 { @media #{$large-up} { font-size: 18px; }; }
cite.MEC16{
color: $COL2;
}
/* Primary:3 */
.MES3 {
background-color: $COL6;
color: $COL2;
padding: 20px 10px;

@media #{$medium-up} {
padding: 40px 20px;

}
 }
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL2;
 }
a.MEC3 { color: $COL2;
&:hover { color: $COL2; }
 }
cite.MEC3{
color: $COL2;
}
/* prod right bar:15 */
.MES15 {
background-color: $COL9;
color: $COL4;
border-radius: 2px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 0 10px;

@media #{$medium-up} {
padding: 0 20px;

}
 }
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: $COL4;
 }
h1.MEC15 { font-size: 17.5px; }
h2.MEC15 { font-size: 18.2px; }
h3.MEC15 { font-size: 14px; }
h4.MEC15 { font-size: 12.6px; }
h5.MEC15 { font-size: 12.6px; }
h6.MEC15 { font-size: 11.2px; }
cite.MEC15{
color: $COL4;
}
/* Temp home:14 */
.MES14 {
background-color: $COL2;
color: $COL2;
padding: 10px;

 }
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL2;
 }
h1.MEC14 { font-size: 10px; }
h1.MEC14 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC14 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC14 { font-size: 10.4px; }
h2.MEC14 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC14 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC14 { font-size: 8px; }
h3.MEC14 { @media #{$medium-up} { font-size: 16.8px; }; }
h3.MEC14 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC14 { font-size: 7.2px; }
h4.MEC14 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC14 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC14 { font-size: 7.2px; }
h5.MEC14 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC14 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC14 { font-size: 6.4px; }
h6.MEC14 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC14 { @media #{$large-up} { font-size: 18px; }; }
cite.MEC14{
color: $COL2;
}
/* Account button:13 */
.MES13 {
background-color: $COL6;
&:hover { background-color: $COL4;}
color: $COL2;
&:hover { color: $COL2;}
border-radius: 5px;
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: $COL2;
 }
/* Footer:12 */
.MES12 {
background-color: $COL4;
color: $COL2;
padding: 20px 10px;

@media #{$medium-up} {
padding: 30px 10px;

}
 }
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: $COL2;
 }
h1.MEC12 { font-size: 10px; }
h1.MEC12 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC12 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC12 { font-size: 10.4px; }
h2.MEC12 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC12 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC12 { font-size: 8px; }
h3.MEC12 { @media #{$medium-up} { font-size: 16.8px; }; }
h3.MEC12 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC12 { font-size: 7.2px; }
h4.MEC12 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC12 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC12 { font-size: 7.2px; }
h5.MEC12 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC12 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC12 { font-size: 6.4px; }
h6.MEC12 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC12 { @media #{$large-up} { font-size: 18px; }; }
a.MEC12 { color: $COL2;
&:hover { color: $COL6; }
 }
cite.MEC12{
color: $COL2;
}
/* White Space:11 */
.MES11 {
background-color: $COL2;
color: $COL4;
padding: 20px 10px;

@media #{$medium-up} {
padding: 30px 10px;

}
@media #{$large-up} {
padding: 40px 10px;

}
 }
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: $COL15;
 }
h1.MEC11 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC11 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC11 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC11 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC11 { @media #{$medium-up} { font-size: 16.8px; }; }
h3.MEC11 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC11 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC11 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC11 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC11 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC11 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC11 { @media #{$large-up} { font-size: 18px; }; }
cite.MEC11{
color: $COL4;
}
/* Box highlight:10 */
.MES10 {
background-color: $COL3;
color: $COL4;
padding: 20px;

@media #{$medium-up} {
padding: 30px;

}
border-width: 1px;
border-style: solid;
border-color: $COL8;
 }
h1.MEC10, h2.MEC10, h3.MEC10, h4.MEC10, h5.MEC10, h6.MEC10 { color: $COL4;
 }
h1.MEC10 { font-size: 22.5px; }
h1.MEC10 { @media #{$medium-up} { font-size: 28px; }; }
h1.MEC10 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC10 { font-size: 23.4px; }
h2.MEC10 { @media #{$medium-up} { font-size: 24px; }; }
h2.MEC10 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC10 { font-size: 18px; }
h3.MEC10 { @media #{$medium-up} { font-size: 22.4px; }; }
h3.MEC10 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC10 { font-size: 16.2px; }
h4.MEC10 { @media #{$medium-up} { font-size: 20px; }; }
h4.MEC10 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC10 { font-size: 16.2px; }
h5.MEC10 { @media #{$medium-up} { font-size: 16px; }; }
h5.MEC10 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC10 { font-size: 14.4px; }
h6.MEC10 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC10 { @media #{$large-up} { font-size: 18px; }; }
cite.MEC10{
color: $COL4;
}
/* shade 2:9 */
.MES9 {
background-color: $COL9;
color: $COL4;
border-radius: 2px;
-webkit-transform: translateZ(0);
overflow: hidden;
@media #{$medium-up} {
padding: 0 20px;

}
@media #{$large-up} {
padding: 0 30px;

}
 }
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL4;
 }
h1.MEC9 { @media #{$medium-up} { font-size: 31.5px; }; }
h1.MEC9 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC9 { @media #{$medium-up} { font-size: 27px; }; }
h2.MEC9 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC9 { @media #{$medium-up} { font-size: 25.2px; }; }
h3.MEC9 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC9 { @media #{$medium-up} { font-size: 22.5px; }; }
h4.MEC9 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC9 { @media #{$medium-up} { font-size: 18px; }; }
h5.MEC9 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC9 { @media #{$medium-up} { font-size: 16.2px; }; }
h6.MEC9 { @media #{$large-up} { font-size: 18px; }; }
cite.MEC9{
color: $COL4;
}
/* grey button:8 */
.MES8 {
background-color: $COL8;
color: $COL2;
&:hover { color: $COL2;}
padding: 10px;

@media #{$medium-up} {
padding: 10px 30px;

}
 }
/* New Style:7 */
.MES7 {
background-color: $COL6;
color: $COL2;
&:hover { color: $COL2;}
padding: 10px;

@media #{$medium-up} {
padding: 10px 15px;

}
@media #{$large-up} {
padding: 10px 30px;

}
 }
/* banner block:6 */
.MES6 {
background-color: $COL7;
color: $COL2;
padding: 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 30px;

}
 }
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: $COL2;
 }
h1.MEC6 { font-size: 22.5px; }
h1.MEC6 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC6 { font-size: 23.4px; }
h2.MEC6 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC6 { font-size: 18px; }
h3.MEC6 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC6 { font-size: 16.2px; }
h4.MEC6 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC6 { font-size: 16.2px; }
h5.MEC6 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC6 { font-size: 14.4px; }
h6.MEC6 { @media #{$large-up} { font-size: 18px; }; }
a.MEC6 { color: $COL2;
&:hover { color: $COL2; }
 }
cite.MEC6{
color: $COL2;
}
/* Banner Panel:5 */
.MES5 {
background-color: $COL2;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://teledent.com.au/img/119/49');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL4;
padding: 30px 10px;

@media #{$medium-up} {
padding: 40px 10px;

}
@media #{$large-up} {
padding: 50px 10px;

}
 }
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL2;
 }
h1.MEC5 { font-size: 22.5px; }
h1.MEC5 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC5 { font-size: 23.4px; }
h2.MEC5 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC5 { font-size: 18px; }
h3.MEC5 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC5 { font-size: 16.2px; }
h4.MEC5 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC5 { font-size: 16.2px; }
h5.MEC5 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC5 { font-size: 14.4px; }
h6.MEC5 { @media #{$large-up} { font-size: 18px; }; }
cite.MEC5{
color: $COL4;
}
/* dark button:4 */
.MES4 {
background-color: $COL4;
color: $COL2;
&:hover { color: $COL2;}
padding: 10px;

@media #{$medium-up} {
padding: 10px 15px;

}
@media #{$large-up} {
padding: 10px 30px;

}
 }
/* Alert:30 */
.MES30 {
background-color: $COL3;
border-width: 2px;
border-style: solid;
border-color: $COL11;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
